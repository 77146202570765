import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import './styles.scss';

const Image = (props) => {
  const { image, className = '', imgStyle, debug } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;
  if (debug) {
    console.log('image debugger: ', image)
  }
  if (image.localFile && (image.localFile.childImageSharp || image.localFile.childrenImageSharp)) {
    return (
      <GatsbyImage
        image={
          image.localFile.childImageSharp?.gatsbyImageData || image.localFile.childrenImageSharp?.[0]?.gatsbyImageData
        }
        className={`gatsby-image ${className}`}
        loading="eager"
        imgStyle={imgStyle || null}
        alt={image.alt || ''}
      />
    );
  }
  if (image.url) {
    return (
      <div className={`gatsby-image ${className} not-optimised`}>
        <img style={imgStyle || null} src={image.url} alt={image.alt || ''} />
      </div>
    );
  }
  return <div className={`gatsby-image placeholder ${className}`} />;
};

export default Image;
