import React, { useState, useEffect, useRef } from 'react';
import { Section, Image, SmallButton, RichText, FadeIn, PlusMinusToggle } from 'components';
import './styles.scss';

const ListImageBreakoutItem = ({ active, handleOnClick, itemIndex, title, content, image, link }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current && active) {
      const { scrollHeight } = contentRef.current;
      setMaxHeight(scrollHeight);
    } else {
      setMaxHeight(0);
    }
  }, [contentRef, active]);
  return (
    <FadeIn className="lib-list-item-container">
      <button
        className="lib-list-item-title"
        onClick={(event) => handleOnClick(event, itemIndex)}
        key={title}
        type="button"
      >
        {title && <RichText html={title} h4 />}
        <PlusMinusToggle active={active} />
      </button>
      <div
        className="lib-list-item-content-container"
        ref={contentRef}
        style={{
          maxHeight,
          opacity: maxHeight ? 1 : 0,
          overflow: maxHeight ? 'visible' : 'hidden',
          visibility: maxHeight ? 'visible' : 'hidden',
        }}
      >
        {image && <Image image={image} className="lib-list-item-image" />}
        {content && <RichText className="lib-list-item-content" html={content} />}
        {link && (
          <SmallButton className="lib-list-item-link" to={link}>
            Learn more
          </SmallButton>
        )}
      </div>
    </FadeIn>
  );
};

const ListImageBreakout = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    breakout_content: content,
    breakout_cta_link: ctaLink,
    breakout_cta_text: ctaText,
    breakout_title: title,
    heading_background: headingBackground,
    alignment,
  } = primary;
  console.log('ALIGNMENT', alignment);

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink && ctaText?.text;

  // state
  const [activeIndex, setActiveIndex] = useState(0);

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex(index);
  };

  return (
    <Section className={`list-img-breakout ${alignment === 'Image Left' ? 'list-img-breakout-left-variation' : ''}`}>
      <div className="lib-container">
        {/* TEXT LIST */}
        <div className="lib-list">
          {items.map((item, index) => {
            const { content: featureContent, title: featureTitle, link, carousel_image: image } = item;
            const fContentHtml = featureContent?.html;
            const fTitleHtml = featureTitle?.html;
            const isActive = index === activeIndex;
            const linkUrl = link?.url;

            return (
              <ListImageBreakoutItem
                active={isActive}
                handleOnClick={handleOnClick}
                itemIndex={index}
                title={fTitleHtml}
                content={fContentHtml}
                link={linkUrl}
                image={image}
              />
            );
          })}
        </div>
        <div className="lib-carousel-breakout">
          {/* CAROUSEL */}
          {items.map((item, index) => {
            const { carousel_image: image, title: featureTitle } = item;
            const isActive = index === activeIndex;
            const fTitleText = featureTitle?.text;
            return (
              <div key={fTitleText} className={`list-img-carousel-onclick ${isActive ? 'active' : 'inactive'}`}>
                <Image image={image} className="list-img-carousel-image" />
              </div>
            );
          })}
          {/* BREAKOUT */}
          {titleHtml?.length > 10 && (
            <div className={`lib-breakout ${headingBackground ? 'black' : 'white'}`}>
              {titleHtml && <RichText className="lib-breakout-title" html={titleHtml} h3 />}
              <div className="lib-breakout-text">
                {contentHtml && <RichText className="lib-breakout-content" html={contentHtml} />}
                {hasCta && (
                  <SmallButton to={ctaLink.url} className="">
                    {ctaText.text}
                  </SmallButton>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default ListImageBreakout;
