import React, { useRef } from 'react';
import { Section, Image, Link, Carousel, RichText } from 'components';
import './styles.scss';

const ImageCarouselWithTextOverlay = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    cta_label: ctaLabel,
    cta_link: ctaLink,
    section_background_color: sectionBackgroundColor,
    card_background: cardBackground,
    card_text_color: cardTextColor,
    text: sectionText,
    title: sectionTitle,
  } = primary;

  const title = sectionTitle.html || sectionTitle.text;
  const text = sectionText.html || sectionText.text;
  const linkLabel = ctaLabel.text || null;
  const link = ctaLink.url;

  /* TESTIMONIAL CAROUSEL */
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 700,
    focusOnSelect: false,
    centerMode: false,
    pauseOnHover: true,
    pauseOnFocus: true,
    adaptiveHeight: true,
  };

  const buttonsRef = useRef(null);

  const breakoutClassName = cardTextColor === 'Light Text' ? 'image-carousel-breakout-light' : null;

  return (
    <Section className="image-carousel" style={{ backgroundColor: sectionBackgroundColor || '#1C1C1C' }}>
      <div
        className={`image-carousel-breakout ${breakoutClassName}`}
        style={{ backgroundColor: cardBackground || '#FFFFFF' }}
      >
        {title && <RichText html={title} h3 />}
        {text && <RichText html={text} />}
        {linkLabel && link && (
          <Link to={link} className="button black outline">
            {linkLabel}
          </Link>
        )}
      </div>
      <div className="image-carousel-container">
        {/* CAROUSEL */}
        <Carousel settings={settings} customControlsRef={buttonsRef}>
          {items.map((img) => {
            const { image } = img;
            return (
              <div className="image-carousel-image-container">
                <Image className="image-carousel-image" image={image} />
              </div>
            );
          })}
        </Carousel>
        <div className="image-carousel-buttons-container" ref={buttonsRef} />
      </div>
    </Section>
  );
};

export default ImageCarouselWithTextOverlay;
