import React from 'react';
import Sticky from 'react-sticky-el';
import { Link, BlogPostCard, Image, Section } from 'components';
import facebookIcon from '../../images/facebook-secondary.svg';
import twitterIcon from '../../images/twitter-secondary.svg';
import linkedinIcon from '../../images/linkedin-secondary.svg';
import './styles.scss';

const BlogPostSideBar = props => {
  const { filteredPosts, sideBarData, location } = props;
  const { to, text, title, content } = sideBarData;
  const { pathname } = location;
  return (
    <Sticky
      stickyClassName="sticky"
      boundaryElement=".blog-post-content-container"
      hideOnBoundaryHit={false}
      className="blog-post-side-bar"
    >
      <Section>
        {/* SHARE */}
        <div className="blog-post-side-bar-socials">
          <span>Share</span>
          <div className="socials-container">
            <a href="/" title="Facebook">
              <Image image={{ url: facebookIcon }} />
            </a>
            <a href="/" title="Twitter">
              <Image image={{ url: twitterIcon }} />
            </a>
            <a href="/" title="LinkedIn">
              <Image image={{ url: linkedinIcon }} />
            </a>
          </div>
        </div>
        {/* //POST LIST */}
          <div className="blog-post-side-bar-card-list">
            {filteredPosts.length > 0 && <span className="blog-post-side-bar-title">Recent articles</span>}
            {filteredPosts.length > 0 &&
              filteredPosts.map(filteredPost => (
                <BlogPostCard
                  key={filteredPost.uid}
                  data={filteredPost.data}
                  uid={filteredPost.uid}
                  className="related-posts"
                />
              ))}
            </div>
        {/* // CTA */}
        {/* {sideBarData && (
          <>
            <span className="blog-post-side-bar-cta-title">{title}</span>
            <p>{content}</p>
            <Link to={to} className="blog-post-side-bar-cta-link">
              {text}
            </Link>
          </>
        )} */}
      </Section>
    </Sticky>
  );
};

export default BlogPostSideBar;
