import React from 'react';
import { Image } from 'components';
import './styles.scss';

const Section = (props) => {
  const { className, containerClassName, children, bgImage, noWrapper, as, style } = props;
  const HtmlElement = as || 'section';
  return (
    <HtmlElement className={`section ${className || ''}`} style={style || {}}>
      {bgImage?.url && <Image image={bgImage} className="section-bg" />}
      {noWrapper ? children : <div className={`wrapper ${containerClassName || ''}`}>{children}</div>}
    </HtmlElement>
  );
};

export default Section;
