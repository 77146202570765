import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'components';
import './styles.scss';

const BlogPostCard = props => {
  const { data, className, uid: slug } = props;
  if (!data) return null;

  const { title, featured_image: heroImage, excerpt, timestamp } = data;

  const titleText = title?.text;
  const hasImage = heroImage;
  const excerptText = excerpt?.text;

  return (
    <section className={`blog-post-card ${className}`}>
      <div className="blog-post-card-container">
        {hasImage && (
          <Link to={`/blog/${slug}`} className="blog-post-card-image-container">
            <Image image={heroImage} />
          </Link>
        )}
        <div className="blog-post-card-text-container">
          {titleText && (
            <Link to={`/blog/${slug}`} className="blog-post-card-title">
              <span>{titleText}</span>
            </Link>
          )}
          {excerptText && <div className="blog-post-card-excerpt">{excerptText}</div>}
          {timestamp && <div className="blog-post-card-time-author">{timestamp && <span>{timestamp}</span>}</div>}
        </div>
      </div>
    </section>
  );
};

export default BlogPostCard;
