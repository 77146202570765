import React from 'react';
import * as styles from './styles.module.scss';

const Message = ({ message, success }) => (
  <div className={success ? styles.fieldSuccess : styles.fieldError}>
    <span>{message || 'This field is required'}</span>
  </div>
);

export default Message;
