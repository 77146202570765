import React, { useState } from 'react';
import BlogPostCard from '../BlogPostCard/index.jsx';
import './styles.scss';

const PostGrid = props => {
  const { posts, className, title, showExcerpt, hasFeaturedPost, isPaginated } = props;

  const totalPosts = posts?.length;
  const [postsToShow, setPostsToShow] = useState(6);
  const [reachedEnd, setReachedEnd] = useState(false);

  const handleLoadMore = event => {
    event.preventDefault();
    setPostsToShow(postsToShow + 6);
    if (postsToShow + 6 > totalPosts) {
      setReachedEnd(true);
    }
  };

  if (!posts || posts.length < 1) return null;

  return (
    <section className={`post-grid ${className || ''}`} key={title}>
      <div className="wrapper">
        {title && <span className="post-grid-title">{title}</span>}
        <div className={`post-grid-container ${className || ''}`}>
          {/* PAGINATED GRID */}
          {isPaginated && posts.length > 0
            ? posts
              .slice(0, postsToShow)
              .map((post, index) => (
                <BlogPostCard
                  key={post?.uid}
                  data={post?.data}
                  uid={post?.uid}
                  className={className}
                  index={index}
                  showExcerpt={showExcerpt || (hasFeaturedPost && index === 0)}
                />
              ))
            : posts.map((post, index) => (
              // FULL GRID
              <BlogPostCard
                key={post?.uid}
                data={post?.data}
                uid={post?.uid}
                className={className}
                index={index}
                showExcerpt={showExcerpt || (hasFeaturedPost && index === 0)}
              />
            ))}
        </div>
      </div>
      {isPaginated && posts.length > postsToShow && !reachedEnd && (
        <div className="post-grid-load-more">
          <button type="button" onClick={handleLoadMore} className="post-grid-button button">
            Load More
          </button>
        </div>
      )}
    </section>
  );
};

export default PostGrid;
