import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section } from 'components';
import './styles.scss';

const bannerQuery = graphql`
  query {
    prismicSettings {
      data {
        text {
          html
          text
        }
      }
    }
  }
`;

const TopBanner = () => {
  const { prismicSettings } = useStaticQuery(bannerQuery);
  const { data } = prismicSettings;
  const { text } = data;
  const textHtml = text?.html;

  if (!textHtml || textHtml.length < 10) return null;

  return (
    <Section className="top-banner">
      {textHtml && <div dangerouslySetInnerHTML={{ __html: textHtml }} className="top-banner-text" />}
    </Section>
  );
};
export default TopBanner;
