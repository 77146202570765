module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Desk Space', // Default Site Title used for SEO & PWA
  description: 'Make it together', // Default Site Decription used for SEO
  siteName: 'Desk Space', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/open-graph.png', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.svg', // Used for manifest favicon generation
  shortName: 'Desk Space', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Desk Space', // Author for schemaORGJSONLD
  themeColor: '#1C1C1C',
  backgroundColor: '#1C1C1C',

  twitter: '', // Twitter Username
  googleTagManagerId: 'GTM-K5BD3DJ',
};
