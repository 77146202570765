
import React from 'react';
import { Link } from 'components';
import './styles.scss';

const SmallButton = props => {
  const { to, title, className = '', children } = props;
  return (
    <Link to={to} title={title || ''} className={`small-button ${className}`}>
      {children}
      <div className="button-arrows">
        <span className="arrow" />
      </div>
    </Link>
  );
};

export default SmallButton;
