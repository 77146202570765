import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Image, Carousel, RichText, FadeIn } from 'components';
import './styles.scss';

const testimonialsQuery = graphql`
  query {
    ...TestimonialsQuery
  }
`;

const ThreeColsTestimonial = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    stat_title_1: statTitle1,
    stat_title_2: statTitle2,
    stat_title_3: statTitle3,
    stat_value_1: statValue1,
    stat_value_2: statValue2,
    stat_value_3: statValue3,
    use_global: useGlobal,
  } = primary;

  const statsData = [
    { title: statTitle1, value: statValue1 },
    { title: statTitle2, value: statValue2 },
    { title: statTitle3, value: statValue3 },
  ];

  const { prismicSettings } = useStaticQuery(testimonialsQuery);
  const { data: prismicData } = prismicSettings;
  const { testimonials } = prismicData;

  const testimonialsToUse = useGlobal ? testimonials : items;

  const controlsRef = useRef(null);

  /* TESTIMONIAL CAROUSEL */
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 700,
    focusOnSelect: false,
    centerMode: false,
    adaptiveHeight: false,
    pauseOnHover: true,
    pauseOnFocus: true,
  };

  return (
    <Section className="testimonial">
      <div className="testimonial-grid">
        <div className="testimonial-section-container">
          <Carousel settings={settings} customControlsRef={controlsRef}>
            {testimonialsToUse.map((testimonial) => {
              const {
                tagline,
                testimonial_author: author,
                testimonial_company: company,
                title,
                image: testimonialImage,
              } = testimonial;
              // content checks
              const titleHtml = title?.html;
              const authorHtml = author?.html;
              const authorText = author?.text;
              const companyHtml = company?.html;
              const taglineHtml = tagline?.html;

              return (
                <div className="testimonial-section" key={authorText}>
                  {testimonialImage && <Image className="testimonial-image" image={testimonialImage} />}
                  <div className="testimonial-text">
                    {taglineHtml && <RichText className="testimonial-tagline" html={taglineHtml} useFadeIn />}
                    {titleHtml && <RichText className="testimonial-title" html={titleHtml} h3 useFadeIn />}
                    <div className="testimonial-author-container">
                      {authorHtml && <RichText className="testimonial-author" html={authorHtml} useFadeIn />}
                      {companyHtml && <RichText className="testimonial-company" html={companyHtml} useFadeIn />}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <FadeIn className="testimonial-controls">
            <div className="spacer" />
            <div ref={controlsRef} />
          </FadeIn>
        </div>

        {/* STATS */}
        <div className="testimonial-stats">
          {statsData.map((item) => {
            const { title, value } = item;
            const titleHtml = title?.html;
            const valueHtml = value?.html;
            return (
              <FadeIn className="testimonial-stat" key={title.text}>
                {valueHtml && (
                  <div className="testimonial-stat-value" dangerouslySetInnerHTML={{ __html: valueHtml }} />
                )}
                {titleHtml && (
                  <div className="testimonial-stat-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />
                )}
              </FadeIn>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default ThreeColsTestimonial;
