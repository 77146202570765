/* eslint-disable indent */
import React from 'react';
import OnVisible from 'react-on-visible';
import { Section, Image, RichText } from 'components';
import './styles.scss';

const MemberTestimonial = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { icon_subtitle: iconSubtitle, icon, title, title_layout: titleLayout } = primary;
  const testimonials = items || null;

  const sectionClassNameMap = {
    Center: 'member-testimonial-center',
    Left: 'member-testimonial-left',
    Right: 'member-testimonial-right',
  };

  const sectionClassName = sectionClassNameMap[titleLayout];

  return (
    <Section className={`member-testimonial ${sectionClassName}`} containerClassName="member-testimonial-container">
      <div className="member-title-container">
        <div className="member-icon-container">
          {icon && <Image className="member-icon" image={icon} />}
          {iconSubtitle && <RichText html={iconSubtitle?.html} />}
        </div>
        {title && <RichText className="member-title" html={title?.html} h3 />}
      </div>
      <div className="testimonial-container">
        {testimonials.map((testimonial) => {
          const {
            testimonial_content: testimonialContent,
            member_name: memberName,
            member_image: memberImage,
            member_company: memberCompany,
          } = testimonial;
          return (
            <div className="member-testimonial">
              {testimonialContent && <RichText className="testimonial-content" html={testimonialContent?.html} p2 />}
              <div className="member-container">
                {memberImage?.url && <Image className="member-photo" image={memberImage} />}
                <div className="member-details">
                  {memberName && <div className="member-name" dangerouslySetInnerHTML={{ __html: memberName?.html }} />}
                  {memberCompany && <div dangerouslySetInnerHTML={{ __html: memberCompany?.html }} />}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default MemberTestimonial;
