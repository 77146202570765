// extracted by mini-css-extract-plugin
export var actions = "styles-module--actions--PGq58";
export var center = "styles-module--center--hCftd";
export var container = "styles-module--container--0nKjO";
export var content = "styles-module--content--A2XFh";
export var noImage = "styles-module--noImage--+3A+f";
export var right = "styles-module--right--oWtAp";
export var section = "styles-module--section--VLMNU";
export var sectionBg = "styles-module--section-bg--iozuB";
export var slightFade = "styles-module--slightFade--2hy95";
export var strongFade = "styles-module--strongFade--y4exe";
export var textBg = "styles-module--textBg--Q3mkD";