import React from 'react';
import { Section, Image, RichText } from 'components';
import * as styles from './styles.module.scss';

const ImageGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, subtitle } = primary;
  const images = items || [];

  const sectionTitle = title?.html || title?.raw;
  const sectionSubtitle = subtitle?.html || subtitle?.raw;

  const rowOne = images.length >= 3 ? [...images].splice(0, 3) : null;
  const rowTwo = images.length > 3 ? [...images].splice(0, 1) : null;
  const rowThree = images.length > 6 ? [...images].splice(0, 3) : null;

  return (
    <Section containerClassName={styles.wrapper}>
      {sectionTitle && (
        <div className={styles.header}>
          <RichText html={sectionTitle} h3 />
          <RichText html={sectionSubtitle} />
        </div>
      )}
      {rowOne && (
        <div className={styles.images}>
          {rowOne.map((img, index) => {
            const image = img?.image || null;
            return (
              <div className={styles.imageContainer} style={{ gridArea: `p${index}` }}>
                {image && <Image className={styles.image} image={image} />}
              </div>
            );
          })}
        </div>
      )}
      {rowTwo && (
        <div className={styles.imagesMid}>
          {rowTwo.map((img, index) => {
            const image = img?.image || null;
            return (
              <div className={styles.imageContainer} style={{ gridArea: `p${index}` }}>
                {image && <Image className={styles.image} image={image} />}
              </div>
            );
          })}
        </div>
      )}
      {rowThree && (
        <div className={styles.images}>
          {rowThree.map((img, index) => {
            const image = img?.image || null;
            return (
              <div className={styles.imageContainer} style={{ gridArea: `p${index}` }}>
                {image && <Image className={styles.image} image={image} />}
              </div>
            );
          })}
        </div>
      )}
    </Section>
  );
};

export default ImageGrid;
