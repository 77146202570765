import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { LogoGrid } from 'components';

const logosQuery = graphql`
  query {
    ...MemberLogosQuery
  }
`;

const MemberLogoGrid = ({ data }) => {
  const { primary } = data;
  const { use_ticker_tape: useTickerTape } = primary;
  const { prismicSettings } = useStaticQuery(logosQuery);
  const { data: prismicData } = prismicSettings;
  const {
    member_logos: logos,
    member_logos_description: content,
    member_logos_title: title,
    member_logos_subtitle: subtitle,
  } = prismicData;

  return <LogoGrid logos={logos} title={title} subtitle={subtitle} content={content} useTickerTape={useTickerTape} />;
};
export default MemberLogoGrid;
