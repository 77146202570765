import React from 'react';
import './styles.scss';

const FormField = ({
  name,
  type,
  options,
  placeholder,
  defaultValue,
  handleChange,
  setValue,
  register,
  validation,
}) => {
  if (type === 'textarea') {
    return (
      <textarea
        {...register(name, validation)}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    );
  }
  if (type === 'select') {
    return (
      <select name={name} {...register(name, validation)}>
        {options.map((option) => (
          <option value={option} defaultValue={defaultValue} key={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  if (type === 'radio' || type === 'checkbox') {
    const onCheckboxChange = (event) => {
      setValue(name, event.target.checked);
    };
    return (
      <div className={`${type}-buttons`}>
        {options.map((option) => (
          <div key={option} className={`${type}-button`}>
            <input
              {...register(name, validation)}
              type={type}
              id={option}
              name={name}
              value={option}
              defaultValue={defaultValue}
              onChange={onCheckboxChange}
            />
            <label htmlFor={option}>
              {type === 'checkbox' && (
                <span className={`${type}-button-input`}>
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6" />
                  </svg>
                </span>
              )}
              {option}
            </label>
          </div>
        ))}
      </div>
    );
  }
  return (
    <input
      {...register(name, validation)}
      type={type}
      name={name}
      id={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

export default FormField;
