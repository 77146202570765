import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import './styles.scss';

const BlogHeader = () => {
  const blogHeading = useStaticQuery(graphql`
    query HeadingData {
      prismicSettings {
        data {
          blog_header_title {
            html
            text
          }
          blog_header_content {
            html
            text
          }
        }
      }
    }
  `);

  const { prismicSettings } = blogHeading;
  const { data } = prismicSettings;
  const { blog_header_title: blogTitle, blog_header_content: blogDescription } = data;
  const titleHtml = blogTitle?.html;
  const descriptionHtml = blogDescription?.html;

  return (
    <OnVisible className="blog-header">
      <div className="wrapper">
        <div className="blog-header-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        <div className="blog-header-description" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      </div>
    </OnVisible>
  );
};

export default BlogHeader;
