import React from 'react';
import Marquee from 'react-fast-marquee';
import { Section, Image, FadeIn, RichText } from 'components';
import './styles.scss';

const LogoGridContainer = ({ children, useTickerTape }) => {
  if (useTickerTape) {
    return (
      <Marquee gradient={false} className="logo-grid-ticker" loop={0} speed={40}>
        {children}
      </Marquee>
    );
  }
  return <div className="logo-grid-container">{children}</div>;
};

const LogoGrid = (props) => {
  const { content, subtitle, title, logos, useTickerTape } = props;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const subtitleHtml = subtitle?.html;

  return (
    <Section className={`logo-grid ${useTickerTape ? 'with-ticker' : ''}`} noWrapper={useTickerTape}>
      <div className="logo-grid-text">
        {titleHtml && <RichText className="logo-grid-title" html={titleHtml} h3 />}
        {subtitleHtml && <RichText className="logo-grid-subtitle" html={subtitleHtml} />}
        {contentHtml && <RichText className="logo-grid-content" html={contentHtml} />}
      </div>
      <LogoGridContainer useTickerTape={useTickerTape}>
        {logos.map((item) => {
          const { logo } = item;
          if (!logo?.url) return null;
          const HtmlElements = useTickerTape ? 'div' : FadeIn;
          return (
            <HtmlElements className="logo-grid-image" key={logo.url}>
              <Image image={logo} />
            </HtmlElements>
          );
        })}
      </LogoGridContainer>
    </Section>
  );
};

export default LogoGrid;
