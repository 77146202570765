import React from 'react';
import { Footer, FooterCta, Header, SEO, NoJs, WmAscii } from 'components';
import 'typeface-inter';
import 'typeface-karla';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, seo } = props;
  return (
    <>
      <NoJs />
      <WmAscii />
      <Header />
      {!customSEO && <SEO {...seo} />}
      {children}
      <FooterCta />
      <Footer />
    </>
  );
};

export default Layout;
