import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FormField, FormMessage } from 'components';
import Arrow from 'images/dark-right-arrow.inline.svg';
import * as styles from './styles.module.scss';

const Form = ({ fields, onSubmit, formSparkId, submitButton, className = '' }) => {
  const formMethods = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' });
  const { register, handleSubmit, formState, reset, setValue } = formMethods;
  const { errors, isSubmitting } = formState;

  const [submissionMessage, setSubmissionMessage] = useState(false);

  const defaultFields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'Pepe',
      label: 'First name',
      className: styles.splitLeft,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      placeholder: 'Silvia',
      label: 'Last name',
      className: styles.splitRight,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your last name',
    },
    {
      type: 'tel',
      name: 'phone',
      placeholder: '0422 123 456',
      label: 'Best Phone number',
      defaultValue: '',
      validation: { required: true, minLength: 8 },
      validationMessage: 'Please enter a valid number',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'carol@hr.com',
      label: 'Email address',
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: "What's on your mind?",
      label: 'Enter a message',
      defaultValue: '',
    },
  ];

  const defaultOnSubmit = async (values) => {
    try {
      const url = `https://submit-form.com/${formSparkId || ''}`; // pass formSparkId to send to formSpark
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      const response = await fetch(url, config);
      // const json = await response.json()
      if (response.ok) {
        // return json
        reset();
        return setSubmissionMessage('Thanks for submitting');
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionMessage('Oops something went wrong, please try again');
    }
  };

  const onSubmitToUse = onSubmit || defaultOnSubmit;

  return (
    <section className={className}>
      <form
        onSubmit={handleSubmit((data) => onSubmitToUse(data, formMethods, setSubmissionMessage))}
        className={`${styles.form} form`}
      >
        <button type="submit" disabled aria-hidden="true" style={{ display: 'none' }} />
        {(fields || defaultFields).map((field) => {
          const hasError = errors[field.name];

          if (field.type === 'hidden') {
            return <FormField {...field} register={register} />;
          }

          return (
            <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
              {field?.label && (
                <div className={styles.label}>
                  {field.label}
                  {field?.validation?.required && <span className={styles.labelRequired}>*</span>}
                </div>
              )}
              <FormField {...field} setValue={setValue} register={register} />
              <input
                {...register('_gotcha')}
                type="checkbox"
                name="_gotcha"
                style={{ display: 'none' }}
                tabIndex="-1"
                autoComplete="off"
              />
              {hasError && <FormMessage message={field.validationMessage} />}
            </div>
          );
        })}
        <button type="submit" className={`button ${styles.formButton}`} disabled={isSubmitting}>
          {isSubmitting ? submitButton?.submissionText || 'Submitting' : submitButton?.defaultText || 'Submit'}
          <Arrow className={styles.arrow} />
        </button>
      </form>
      {submissionMessage && <FormMessage message={submissionMessage} success />}
    </section>
  );
};

export default Form;
