import * as styles from '../../components/Form/styles.module.scss';

const firstName = {
  type: 'text',
  name: 'firstName',
  label: 'First name',
  placeholder: 'What is your first name?',
  defaultValue: '',
  validation: { required: true },
  validationMessage: 'Please enter your first name',
  hubspotKey: 'firstname',
};

const lastName = {
  type: 'text',
  name: 'lastName',
  label: 'Last name',
  placeholder: 'What is your last name?',
  defaultValue: '',
  validation: { required: true },
  validationMessage: 'Please enter your last name',
  hubspotKey: 'lastname',
};

const name = {
  type: 'text',
  name: 'name',
  label: 'Name',
  placeholder: 'What is your name?',
  defaultValue: '',
  validation: { required: true },
  validationMessage: 'Please enter your name',
  hubspotKey: 'name',
};

const email = {
  type: 'email',
  name: 'email',
  placeholder: 'What is your email address?',
  label: 'Email',
  defaultValue: '',
  validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
  validationMessage: 'Please enter a valid email',
  hubspotKey: 'email',
};

const phone = {
  type: 'tel',
  name: 'phone',
  placeholder: 'What is your best contact number?',
  label: 'Phone Number',
  defaultValue: '',
  validation: { required: true, minLength: 8 },
  validationMessage: 'Please enter a valid number',
  hubspotKey: 'phone',
};

const message = {
  type: 'textarea',
  name: 'message',
  placeholder: 'Leave us a message',
  label: 'Message',
  defaultValue: '',
  validation: { required: false },
  validationMessage: 'Please enter a message',
  hubspotKey: 'message',
};

const companyName = {
  type: 'text',
  name: 'companyName',
  placeholder: "What's your company name",
  label: 'Company Name',
  defaultValue: '',
  validation: { required: false },
  validationMessage: 'Please enter your companu name',
  hubspotKey: 'companyName',
};

const companySize = {
  type: 'number',
  name: 'companySize',
  placeholder: 'What is your company size?',
  label: 'Company Size',
  defaultValue: '',
  validation: { required: false },
  validationMessage: 'Please enter your company size',
  hubspotKey: 'companysize',
};

const membership = {
  type: 'select',
  name: 'membership',
  options: ['Please Select', 'Private Office', 'Open Plan', 'Part Time', 'Meeting Room'],
  placeholder: 'Please Select',
  label: 'Preferred Membership',
  defaultValue: '',
  validation: { required: false },
  validationMessage: 'Please select preferred membership',
  hubspotKey: 'preferredmembership',
};

const contract = {
  type: 'select',
  name: 'contract',
  options: ['Please Select', 6, 12, 18, 24],
  placeholder: 'Please Select',
  label: 'Preferred Contract Length',
  defaultValue: '',
  validation: { required: false },
  validationMessage: 'Please select preferred contract length',
  hubspotKey: 'contractlength',
};

const startDate = {
  type: 'date',
  name: 'startDate',
  label: 'Start Date',
  placeholder: 'Select start date',
  defaultValue: '',
  validation: { required: false },
  validationMessage: 'Please select start date',
  hubspotKey: 'startdate',
};

export const contactUsFields = [
  {
    ...firstName,
    className: styles.splitLeft,
  },
  {
    ...lastName,
    className: styles.splitRight,
  },
  {
    ...phone,
    className: styles.splitLeft,
  },
  {
    ...email,
    className: styles.splitRight,
  },
  {
    ...message,
  },
];

export const joinUsFields = [
  {
    ...firstName,
    className: styles.splitLeft,
  },
  {
    ...lastName,
    className: styles.splitRight,
  },
  {
    ...email,
    className: styles.splitLeft,
  },
  {
    ...phone,
    className: styles.splitRight,
  },
  {
    ...companyName,
    className: styles.splitLeft,
  },
  {
    ...companySize,
    className: styles.splitRight,
  },
  {
    ...startDate,
    className: styles.splitLeft,
  },
  {
    ...membership,
    className: styles.splitRight,
  },
  {
    ...contract,
    // className: styles.splitRight,
  },
];
