import React from 'react';
import { Section, Image, Link, RichText, FadeIn } from 'components';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import './styles.scss';

const TwoColsTextImage = (props) => {
  const { data } = props;
  const { primary, id } = data;
  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    text_alignment: alignment,
    hide_image_on_mobile: hideImageOnMobile,
    image,
    title,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url || image?.localFile;
  const hasCta = ctaLink && ctaText?.text;
  const hasSecondaryCta = secondaryCtaLink && secondaryCtaText?.text;

  // const showArrow = sliceIndex === 0;
  const showArrow = false;

  const scrollToNext = (event) => {
    event.preventDefault();
    const { nextSibling } = document.getElementById(id);
    if (nextSibling) smoothScrollIntoView(nextSibling, { behavior: 'smooth', block: 'start' });
  };

  const alignmentClassNameMap = {
    Left: 'left-align',
    Center: 'center-align',
    Right: 'right-align',
  };

  return (
    <>
      <Section
        className={`text-image ${hasImage ? '' : 'without-image'} ${alignmentClassNameMap[alignment]} ${
          hideImageOnMobile ? 'hide-image-on-mobile' : ''
        }`}
        id={id}
      >
        <div className="text-image-grid">
          <div className="text-image-text">
            {titleHtml && <RichText className="text-image-title" html={titleHtml} h1 useFadeIn />}
            {contentHtml && <RichText className="text-image-content" html={contentHtml} useFadeIn />}
            {hasCta && (
              <FadeIn className="text-image-actions">
                <Link to={ctaLink.url} className="button">
                  {ctaText.text}
                </Link>
                {hasSecondaryCta && (
                  <Link to={secondaryCtaLink.url} className="button black outline">
                    {secondaryCtaText.text}
                  </Link>
                )}
              </FadeIn>
            )}
          </div>
          {hasImage && (
            <FadeIn className="text-image-img">
              <Image image={image} />
            </FadeIn>
          )}
        </div>
      </Section>
      {showArrow && (
        <div className="text-image-scroll-container">
          <Link to="#discover" className="text-image-scroll-text" onClick={scrollToNext}>
            Scroll
          </Link>
          <div className="text-image-scroll-line" />
        </div>
      )}
    </>
  );
};

export default TwoColsTextImage;
