import React from 'react';
import { Section, Link, Image, FadeIn, RichText, Carousel } from 'components';
import './styles.scss';

const Grid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    content,
    number_of_columns: numberOfColumns,
    title,
    cta_text: mainCtaText,
    cta_link: mainCtaLink,
    title_alignment: titleAlignment,
  } = primary;

  const cols = !numberOfColumns || numberOfColumns > 3 ? 4 : numberOfColumns;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasLink = mainCtaText?.text && mainCtaLink?.url;

  return (
    <Section className="grid-slice" containerClassName="grid-slice-container">
      {titleHtml && (
        <div className={`grid-slice-header ${titleAlignment === 'Center' ? 'center-align' : 'left-align'}`}>
          <RichText className="grid-slice-title" html={titleHtml} h3 />
          {contentHtml && contentHtml.length > 9 && <RichText className="grid-slice-content" html={contentHtml} />}
        </div>
      )}
      <Carousel
        settings={{
          dots: true,
          centerMode: false,
          draggable: true,
          arrows: false,
          variableWidth: true,
          className: `grid-slice-grid col-count-${cols}`,
          responsive: [
            // Display carousel only on mobile devices
            { breakpoint: 10000, settings: 'unslick' },
            { breakpoint: 1024 },
          ],
        }}
      >
        {items.map((item) => {
          const {
            cta_link: ctaLink,
            cta_text: ctaText,
            column_content: colContent,
            column_tagline: colTagline,
            column_title: colTitle,
            image_type: imageType,
            hide_image_mobile: hideImageMobile,
            image,
          } = item;
          const hasCta = ctaLink && ctaText?.text;
          const colTitleHtml = colTitle?.html;
          const colContentHtml = colContent?.html;
          const colTaglineHtml = colTagline?.html;
          const hasImage = image?.url;
          const imageClass = imageType === 'Icon' ? 'grid-slice-icon' : 'grid-slice-img';
          return (
            <FadeIn className="grid-slice-grid-cell">
              {hasImage && (
                <Image className={`${imageClass} ${hideImageMobile ? 'hide-image-mobile' : ''}`} image={image} />
              )}
              <div className="grid-slice-cell-body">
                {colTitleHtml && <RichText className="grid-slice-cell-title" html={colTitleHtml} h5 />}
                {colContentHtml && <RichText className="grid-slice-cell-content" html={colContentHtml} />}
                {colTaglineHtml && <RichText className="grid-slice-cell-tagline" html={colTaglineHtml} />}
              </div>
              {hasCta && (
                <Link to={ctaLink.url} className="button black outline">
                  {ctaText.text}
                </Link>
              )}
            </FadeIn>
          );
        })}
      </Carousel>
      {hasLink && (
        <Link className="grid-slice-cta button" to={mainCtaLink.url}>
          {mainCtaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default Grid;
