import React, { useState } from 'react';
import { Link, Section, RichText, PlusMinusToggle } from 'components';
// import { getLinkTo } from 'utils/helpers';
import './styles.scss';

const FaqAccordion = props => {
  const { data } = props;
  const { primary, items } = data;

  const { title, cta_link: ctaLink, cta_text: ctaText } = primary;

  const [openIndexes, setOpenIndexes] = useState([0]);

  const titleHtml = title?.html;
  const ctaTextText = ctaText?.text;
  // const ctaLinkUrl = getLinkTo(ctaLink);

  const toggleFaq = (event, index) => {
    event.preventDefault();
    const indexOfExisting = openIndexes.indexOf(index);
    const clonedArr = [...openIndexes];
    if (indexOfExisting === -1) {
      clonedArr.push(index);
    } else {
      clonedArr.splice(indexOfExisting, 1);
    }
    setOpenIndexes(clonedArr);
  };

  const escapeDoubleQuotes = str => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const schema = `
  <script type="application/ld+json">
    {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      ${items.map(
    item => `
        {
          "@type": "Question",
          "name": "${item.question.text}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${escapeDoubleQuotes(item.answer.html)}"
          }
        }`
  )}]
    }
    </script>
  `;

  return (
    <Section className="faq">
      {titleHtml && <RichText className="faq-title" html={titleHtml} h3 />}
      <div className="faq-accordion">
        {items.map((item, index) => (
          <FaqItem
            key={item.question.text}
            toggleFaq={toggleFaq}
            openIndexes={openIndexes}
            itemIndex={index}
            {...item}
          />
        ))}
      </div>
      {ctaTextText && (
        <Link to={ctaLink.url} className="button square">
          {ctaTextText}
        </Link>
      )}
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </Section>
  );
};

const FaqItem = props => {
  const { question, answer, toggleFaq, openIndexes, itemIndex } = props;

  const active = openIndexes.indexOf(itemIndex) !== -1;

  if (!answer?.html || answer.html.length < 10 || !question?.html || question?.html.length < 10) return null;

  return (
    <div className={`faq-accordion-item ${active ? 'active' : 'inactive'}`}>
      <a className="faq-accordion-question" href="#toggle" onClick={(event) => toggleFaq(event, itemIndex)}>
        <RichText className="faq-accordion-question-title" html={question?.html} h5 />
        <PlusMinusToggle active={active} />
      </a>
      <RichText className="faq-accordion-answer" html={answer.html} />
    </div>
  );
};

export default FaqAccordion;
