import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Image, Link } from 'components';
import './styles.scss';

const footerCtaQuery = graphql`
  query {
    ...FooterCtaQuery
  }
`;

const FooterCta = () => {
  const { prismicSettings } = useStaticQuery(footerCtaQuery);
  const { data: prismicData } = prismicSettings;

  const {
    map_title: mapTitle,
    map_link: mapLink,
    map_background: mapBg,
    map_image: mapImage,
    primary_cta_text: primaryCtaText,
    primary_cta: primaryCta,
    secondary_cta_text: secondaryCtaText,
    secondary_cta: secondaryCta,
  } = prismicData;

  const actions = [
    {
      to: primaryCta?.url,
      text: primaryCtaText?.text,
      className: 'button',
    },
    {
      to: secondaryCta?.url,
      text: secondaryCtaText?.text,
      className: 'button white outline',
    },
  ];

  // Commented out, remove map from footer

  // const mapTitleHtml = mapTitle?.html;

  // return (
  //   <>
  //     <Section className="footer-map" bgImage={null /* mapBg */} noWrapper>
  //       {/* Fix to remove the map (Client Feedback) */}
  //       {/* <Link className="footer-map-link" to={mapLink?.url}> */}
  //       {/* <span className="footer-map-dot" /> */}
  //       <div className="wrapper">
  //         <div className="footer-map-text" dangerouslySetInnerHTML={{ __html: mapTitleHtml }} />
  //       </div>
  //       {/* </Link> */}
  //     </Section>
  //     <Section className="footer-cta">
  //       {actions.map((action) => (
  //         <Link to={action.to} key={action.text} className={action.className}>
  //           {action.text}
  //         </Link>
  //       ))}
  //     </Section>
  //   </>
  // );
  return <div> </div>;
};

export default FooterCta;
