import React from 'react';
import OnVisible from 'react-on-visible';
import { Section } from 'components';
import './styles.scss';

const Wysiwyg = props => {
  const { data } = props;
  const { primary } = data;
  const { wysiwyg } = primary;

  const wysiwygHtml = wysiwyg?.html;

  return (
    <OnVisible className="wysiwyg">
      <Section>
        {wysiwygHtml && <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: wysiwygHtml }} />}
      </Section>
    </OnVisible>
  );
};

export default Wysiwyg;
