import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, PostGrid, Image, Link, SmallButton } from 'components';
import './styles.scss';

const BlogPosts = props => {
  const { data } = props;
  const { primary } = data;
  const { title } = primary;

  const titleHtml = title?.html;

  const blogPostQuery = graphql`
    query {
      ...allBlogposts
    }
  `;

  const { allPrismicBlogPost } = useStaticQuery(blogPostQuery);
  const { nodes: blogPosts } = allPrismicBlogPost;

  return (
    <Section className="blog-posts">
      {titleHtml && (
        <div className="blog-posts-header">
          <div className="blog-posts-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />
          <Link to="/blog" className="blog-posts-view button">
            View all
          </Link>
        </div>
      )}

      <div className="blog-posts-container">
        {blogPosts.map(post => {
          const { data: postData, uid } = post;
          const { featured_image: image, title: featureTitle } = postData;
          return (
            <div className="blog-posts-post" key={uid}>
              <Link to={`/blog/${uid}`}>
                <Image className="blog-post-post-image" image={image} />
              </Link>
              <Link to={`/blog/${uid}`}>
                <span className="blog-post-title">{featureTitle.text}</span>
              </Link>
              <SmallButton className="blog-post-link" to={`/blog/${uid}`}>
                Discover
              </SmallButton>
            </div>
          )
        })}
      </div>
    </Section>
  );
};

export default BlogPosts;
