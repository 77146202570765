import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Image, Link, Section } from 'components';
import facebook from '../../images/facebook.svg';
import linkedin from '../../images/linkedin.svg';
import spotify from '../../images/spotify.svg';
import instagram from '../../images/instagram.svg';
import indigenousFlag from '../../images/indigenous-flag.svg';
import googlePlay from '../../images/google-play.svg';
import appStore from '../../images/app-store.svg';
import './styles.scss';

const footerMapLink = graphql`
  query {
    ...FooterQuery
  }
`;

const Footer = () => {
  const { prismicSettings } = useStaticQuery(footerMapLink);
  const { data: prismicData } = prismicSettings;
  const { map_link: mapLink } = prismicData;

  return (
    <Section className="footer" as="footer">
      <div className="sitemap">
        <div className="sitemap-text">
          <div className="footer-col">
            <p className="footer-col-title">Visit us</p>
            <Link to={mapLink?.url}>
              <p>85 William St</p>
              <p>Darlinghurst,</p>
              <p>2010, NSW</p>
            </Link>
          </div>
          <div className="footer-col">
            <p className="footer-col-title">Contact us</p>
            <a href="/tour/">Book a tour</a>
            <a href="mailto:joinus@deskspace.com.au">Email us</a>
            <a href="tel:1300 970 483">1300 970 483</a>
          </div>
          <div className="footer-col">
            <p className="footer-col-title">Company</p>
            <Link className="footer-blog-link" to="/blog">
              Blog
            </Link>
            <Link className="footer-blog-link" to="/memberships">
              Pricing
            </Link>
          </div>
          <div className="footer-col">
            <p className="footer-col-title">Download</p>
            <Link
              className="footer-blog-link"
              to="https://play.google.com/store/apps/details?id=com.officernd.deskspace&hl=en_US"
            >
              Android App
            </Link>
            <Link className="footer-blog-link" to="https://apps.apple.com/au/app/desk-space-sydney/id1490556703">
              iOS App
            </Link>
          </div>
          <div className="footer-col">
            <p className="footer-col-title">Follow Us</p>
            <div className="footer-socials">
              <div className="footer-socials-links">
                <Link to="https://www.facebook.com/deskspacesydney">
                  <Image image={{ url: facebook }} />
                </Link>
                <Link to="https://www.instagram.com/desk_space/">
                  <Image image={{ url: instagram }} />
                </Link>
                <Link to="https://www.linkedin.com/company/desk-space">
                  <Image image={{ url: linkedin }} />
                </Link>
                <Link to="https://open.spotify.com/user/d5cw1iu83n6tz8sfoenn1twrw?si=aa8f8090f1c242a4">
                  <Image image={{ url: spotify }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="acknowledgement-text">
        <Image image={{ url: indigenousFlag }} className="footer-flag" />
        <p>
          We pay our respects to First Nations knowledge holders and recognise the unceded lands on which we live, work,
          and learn.
        </p>
      </div>
      <div className="credit-text">
        <p>
          <a href="https://mammoth.tech" title="Made by Mammoth">
            Made by Mammoth
          </a>
        </p>
      </div>
    </Section>
  );
};

export default Footer;
