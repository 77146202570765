import React from 'react';
import classNames from 'classnames';
import { Section, RichText, FadeIn, Link } from 'components';
import * as styles from './styles.module.scss';

const Hero = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    title,
    hero_image: heroImage,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    text_alignment: alignment,
    text_background: textBg,
    image_fade: imageFade,
  } = primary;

  const heroTitle = title.html || title.text;
  const contentHtml = content.html;
  const image = heroImage;
  const hasCta = ctaLink && ctaText?.text;
  const hasSecondaryCta = secondaryCtaLink && secondaryCtaText?.text;

  const imageFadeClassNameMap = {
    'Solid Image': styles.solidImage,
    'Slight fade': styles.slightFade,
    'Strong fade': styles.strongFade,
  };

  const imageFadeClassName = imageFadeClassNameMap[imageFade];

  const alignmentClassNameMap = {
    Left: styles.left,
    Center: styles.center,
    Right: styles.right,
  };

  const alignmentClassName = alignmentClassNameMap[alignment];

  const combinedClassNames = classNames(styles.section, {
    [alignmentClassName]: alignment,
    [styles.textBg]: textBg,
    [styles.noImage]: !image?.url,
    [imageFadeClassName]: imageFade,
  });

  return (
    <Section bgImage={image} className={combinedClassNames} containerClassName={styles.container}>
      <div className={styles.content}>
        {heroTitle && <RichText html={heroTitle} h1 useFadeIn />}
        {contentHtml && <RichText html={contentHtml} useFadeIn p3 />}
        {hasCta && (
          <FadeIn className={styles.actions}>
            <Link to={ctaLink.url} className="button">
              {ctaText.text}
            </Link>
            {hasSecondaryCta && (
              <Link to={secondaryCtaLink.url} className="button white outline">
                {secondaryCtaText.text}
              </Link>
            )}
          </FadeIn>
        )}
      </div>
    </Section>
  );
};

export default Hero;
