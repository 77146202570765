import React from 'react';
import classNames from 'classnames';
import { FadeIn } from 'components';
// import * as styles from './styles.module.scss';

const RichText = React.forwardRef((props) => {
  const { html, h1, h2, h3, h4, h5, h6, p1, p2, p3, className, useFadeIn, subheading, style, richTextRef } = props;

  if (!html || html.length < 10) return null;

  const combinedClassNames = classNames('rich-text', {
    [className]: className,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p1,
    p2,
    p3,
    subheading,
  });

  if (useFadeIn) {
    return (
      <FadeIn
        ref={richTextRef || null}
        className={combinedClassNames}
        dangerouslySetInnerHTML={{ __html: html }}
        style={style || {}}
      />
    );
  }

  return (
    <div
      className={combinedClassNames}
      dangerouslySetInnerHTML={{ __html: html }}
      ref={richTextRef || null}
      style={style || {}}
    />
  );
});

export default RichText;
