import React, { useState } from 'react';
import { Link, RichText } from 'components';
import Chevron from '../../images/white-chevron.inline.svg';

const Navigation = (props) => {
  const { classNamePrefix = 'main', links, useCta } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';
  // state
  const [showSublinks, setShowSublinks] = useState(false);
  // future logic/state for multiple main links with sublinks
  // const [activeSublinksIndex, setActiveSublinksIndex] = useState(false);

  // toggle sub links for mobile
  const showNavSublinks = (event) => {
    event.preventDefault();
    // setActiveSublinksIndex(index);
    setShowSublinks(!showSublinks);
  };

  const sublinksOpen = showSublinks ? 'active' : '';

  return (
    <nav className={className || ''}>
      {links &&
        links.map((link, index) => {
          const { text, to, className: navClassName, sublinks } = link;
          const hasSublinks = sublinks?.length > 0;
          const mainClassName = `${className}-link`;

          if (useCta && index === links.length - 1) {
            return (
              <Link key={text} className="button white outline" to={to}>
                {text}
              </Link>
            );
          }
          // L I N K   C O N T A I N E R
          return (
            <div
              key={text}
              className={`${mainClassName} ${navClassName || ''} ${hasSublinks ? 'has-dropdown' : ''} ${sublinksOpen}`}
            >
              {/* M A I N   L I N K */}
              <div className={`${className}-link-text`}>
                <Link to={to}>{text}</Link>
                {hasSublinks && (
                  <button type="button" className="header-nav-link-chevron" onClick={(event) => showNavSublinks(event)}>
                    <Chevron />
                  </button>
                )}
              </div>
              {/* S U B L I N K S  C O N T A I N E R */}
              {hasSublinks && (
                <div className={`${className}-sublinks`}>
                  {link.sublinks.map((sublink) => {
                    // S U B L I N K
                    const { text: sublinkText, to: sublinkTo, subtext, className: subClassName } = sublink;
                    return (
                      <div
                        key={sublinkText}
                        className={`${className}-sublink ${subClassName || ''}`}
                        style={{ transitionDelay: `${index * 0.1}s` }}
                      >
                        <Link to={sublinkTo} className={`${className}-sublink-link`}>
                          <span className={`${className}-sublink-text`}>{sublinkText}</span>
                          <RichText className={`${className}-sublink-subtext`} html={subtext} />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
    </nav>
  );
};

export default Navigation;
