import React from 'react';
import { Section, Image, SmallButton, FadeIn, RichText } from 'components';
import './styles.scss';

const TextColumns = (props) => {
  const { data } = props;
  const { items } = data;

  return (
    <div className="text-columns">
      <Section className="text-columns-container">
        {items.map((card) => {
          const {
            background,
            card_text_color: textColor,
            content,
            cta_link: ctaLink,
            cta_text: ctaText,
            subtitle,
            title,
            image,
            image_type: type,
          } = card;

          const backgroundColor = background;
          const textVariant = textColor && textColor.toLowerCase().replace(/\s+/g, '-');
          const imageVariant = type === 'Image' ? 'with-image' : '';

          return (
            <div
              className={`text-columns-card ${textVariant || 'dark-text'} ${imageVariant}`}
              style={{ backgroundColor }}
              key={title?.text}
            >
              {image && image.url && (
                <FadeIn>
                  <Image image={image} />
                </FadeIn>
              )}
              <div className="text-columns-content-container">
                {title?.html && <RichText className="text-columns-title" html={title?.html} h4 useFadeIn />}
                {subtitle?.html && <RichText className="text-columns-subtitle" html={subtitle?.html} useFadeIn />}
                {content?.html && <RichText className="text-columns-content" html={content?.html} useFadeIn />}
                {ctaLink?.url && (
                  <SmallButton className="text-columns-cta" to={ctaLink.url}>
                    {ctaText.text}
                  </SmallButton>
                )}
              </div>
            </div>
          );
        })}
      </Section>
    </div>
  );
};

export default TextColumns;
