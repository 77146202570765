// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--ToMGc";
export var field = "styles-module--field--ltA6u";
export var fieldError = "styles-module--fieldError--++zwb";
export var form = "styles-module--form--wiw5P";
export var formButton = "styles-module--formButton---rmdF";
export var label = "styles-module--label--F4qoU";
export var labelRequired = "styles-module--labelRequired--B700Q";
export var splitLeft = "styles-module--splitLeft--5+eyv";
export var splitRight = "styles-module--splitRight--o0XJZ";
export var submissionMessage = "styles-module--submissionMessage--YuBbJ";