import React from 'react';
import { FadeIn, Section, RichText, Form } from 'components';
import * as fields from './fields';
import * as styles from './styles.module.scss';

const FormSlice = (props) => {
  const { data, location, navigate, isGeoPage, isBlog } = props;
  const { primary } = data;
  const { content, theme, field_type: type, title } = primary;
  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  // theme class
  const themeClass = theme ? styles.darkmode : '';

  const formMapper = {
    'Contact Us': {
      fields: fields.joinUsFields,
      hubspotId: 'ba0d5209-3019-4e3b-8570-7b4232d95adb',
      eventName: 'contact_form_submission',
    },
  };

  const fieldsToUse = formMapper[type]?.fields || fields.b2b2cFields;
  const hubspotFormIdToUse = formMapper[type]?.hubspotId || '1fe6f61b-d51f-4c71-b3eb-9549716e4837';

  if (!fieldsToUse || !hubspotFormIdToUse) {
    return null;
  }

  const onSubmit = async (values, formMethods, setSubmissionMessage) => {
    formMethods?.reset();
    if (navigate) {
      return navigate('/enquiry-received/');
    }
    return setSubmissionMessage('Thanks for submitting');
  };

  return (
    <Section
      className={styles.section}
      containerClassName={styles.container}
      sliceName="Form"
      id="form"
      spacing={isBlog ? 's' : 'm'}
    >
      <div className={styles.header}>
        <div className={styles.titleContentContainer}>
          {titleHtml && (
            <FadeIn>
              <RichText className={`${styles.title} ${isGeoPage && styles.geoPageTitle}`} h4 html={titleHtml} />
            </FadeIn>
          )}
          {contentHtml && (
            <FadeIn>
              <RichText className={styles.content} html={contentHtml} />
            </FadeIn>
          )}
        </div>
        <div className={styles.form}>
          <Form fields={fieldsToUse} onSubmit={onSubmit} className={styles.form} />
        </div>
      </div>
    </Section>
  );
};

export default FormSlice;
