import React from 'react';
import { Section } from 'components';
import './styles.scss';

const BlogPostHeader = props => {
  const { title, timestamp } = props;
  // content checks
  const titleHtml = title?.html;

  return (
    <Section className="blog-post-header">
      {/* <CategoryList categories={categories} /> */}
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className="blog-post-title" />}
      {/* <PostDetails timestamp={timestamp} /> */}
      {/* <span className="blog-post-dot"> &nbsp; • &nbsp; </span> */}
      {timestamp && <span className="blog-post-timestamp">Published: {timestamp}</span>}
      {/* <span className="blog-post-readtime">X min read</span> */}
    </Section>
  );
};

export default BlogPostHeader;
