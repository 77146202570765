import React from 'react';
import { Section, Image, Link, FadeIn, RichText } from 'components';
import './styles.scss';

const TwoCols = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    image_left: imageLeft,
    tagline,
    title,
    image_link: imageLink,
    background_color: backgroundColor,
    card_background: cardBackgroundColour,
    text_color: textColor,
  } = primary;

  // content checks;
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasImage = image?.url;
  const hasCta = ctaLink?.url && ctaText?.text;
  const imageAlign = imageLeft ? 'left-align' : '';
  const bgColor = backgroundColor;
  const cardBgColor = cardBackgroundColour || 'none';

  const contentColorClass = textColor === 'Light Text' ? 'light-text' : 'dark-text';

  return (
    <Section
      style={bgColor ? { backgroundColor: bgColor } : {}}
      className={`two-cols ${contentColorClass} ${imageAlign ? 'left' : ''}`}
    >
      <div
        className={`two-cols-text ${cardBackgroundColour ? 'has-custom-bg' : ''}`}
        style={{ backgroundColor: cardBgColor || 'none' }}
      >
        {taglineHtml && <RichText className="two-cols-tagline" html={taglineHtml} useFadeIn />}
        {titleHtml && <RichText h3 className="two-cols-title" html={titleHtml} useFadeIn />}
        {contentHtml && <RichText className="two-cols-content" html={contentHtml} useFadeIn />}
        {hasCta && (
          <FadeIn>
            <Link className="text-grid-cta button black outline" to={ctaLink.url}>
              {ctaText.text}
            </Link>
          </FadeIn>
        )}
      </div>
      {hasImage && (
        <FadeIn className="two-cols-image-link">
          <Link to={imageLink?.url || ''}>
            <Image className="two-cols-image" image={image} />
          </Link>
        </FadeIn>
      )}
    </Section>
  );
};

export default TwoCols;
