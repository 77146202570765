import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Slider from 'react-slick';
import ArrowLeftSvg from '../../images/dark-left-arrow.inline.svg';
import ArrowRightSvg from '../../images/dark-right-arrow.inline.svg';
import './styles.scss';

const Arrow = (props) => {
  const { className, style, onClick, portalRef, next } = props;
  if (!portalRef) return null;
  return ReactDOM.createPortal(
    <button type="button" style={style} className={className} onClick={onClick} aria-label="Change Slide">
      {next ? <ArrowRightSvg /> : <ArrowLeftSvg />}
    </button>,
    portalRef
  );
};

const Dots = ({ dots, portalRef }) => {
  if (!portalRef) return null;
  return ReactDOM.createPortal(<ul className="slick-dots">{dots}</ul>, portalRef);
};

const Carousel = (props) => {
  const { children, containerClassName, sliderRef, customControlsRef, settings } = props;

  const [portalRef, setPortalRef] = useState(null);

  const settingsObj = {
    nextArrow: <Arrow portalRef={portalRef} next />,
    prevArrow: <Arrow portalRef={portalRef} />,
    appendDots: (dots) => <Dots dots={dots} portalRef={portalRef} />,
    ...settings,
  };

  // Pass sliderRef to parent component
  const sliderElement = useRef(null);
  const controlsRef = useRef();

  useEffect(() => {
    if (customControlsRef) {
      setPortalRef(customControlsRef?.current);
    }
    if (!customControlsRef && controlsRef) {
      setPortalRef(controlsRef?.current);
    }
  }, [customControlsRef, controlsRef]);

  useEffect(() => {
    if (sliderRef) {
      sliderRef(sliderElement);
    }
  }, [sliderRef]);

  return (
    <div className={`carousel ${containerClassName || ''}`}>
      <Slider {...settingsObj} ref={sliderElement}>
        {children}
      </Slider>
      {!customControlsRef && (
        <div className="carousel-controls-container">
          <div className="carousel-controls" ref={controlsRef} />
        </div>
      )}
    </div>
  );
};

export default Carousel;
