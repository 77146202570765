import React from 'react';
import OnVisible from 'react-on-visible';
import * as styles from './styles.module.scss';

const FadeIn = ({ children, className = '', style, dangerouslySetInnerHTML }) => {
  if (dangerouslySetInnerHTML) {
    return (
      <OnVisible
        className={`${styles.fadeIn} ${className}`}
        visibleClassName={styles.visible}
        style={style}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
    );
  }

  return (
    <OnVisible className={`${styles.fadeIn} ${className}`} visibleClassName={styles.visible} style={style}>
      {children}
    </OnVisible>
  );
};

export default FadeIn;
