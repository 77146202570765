import React from 'react';
import { Section, Image, RichText, Button, FadeIn } from 'components';
import './styles.scss';

const ImageStatsOverlay = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { background_image: bgImage, title, top_image: topImage, cta_text: ctaText, cta_link: ctaLink } = primary;

  const titleHtml = title?.html;

  return (
    <Section className="image-stats" bgImage={bgImage} noWrapper>
      <div className="image-stats-breakout">
        <div className="image-stats-text">
          {titleHtml && <RichText className="image-stats-title" html={titleHtml} h3 useFadeIn />}
          {items.map((item) => {
            const { stat_title: statTitle, stat_value: statValue } = item;
            const sTitleText = statTitle?.text;
            const sTitleHtml = statTitle?.text;
            const sValueHtml = statValue?.text;
            return (
              <div className="image-stats-item" key={sTitleText}>
                {sTitleHtml && <div className="image-stats-item-title">{sTitleText}</div>}
                {sValueHtml && <div className="image-stats-item-value">{sValueHtml}</div>}
              </div>
            );
          })}
          {ctaLink?.url && (
            <FadeIn>
              <Button className="image-stats-cta" theme="black" outline to={ctaLink.url}>
                {ctaText.text}
              </Button>
            </FadeIn>
          )}
        </div>
        <Image className="image-stats-breakout-img" image={topImage} />
      </div>
    </Section>
  );
};

export default ImageStatsOverlay;
