import React, { useState, useRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, Link, Image, FadeIn, RichText } from 'components';
import './styles.scss';

const featuresQuery = graphql`
  query {
    ...FeatureGridQuery
  }
`;

const FeatureGrid = (props) => {
  const { data } = props;

  const { primary, items: dataItems } = data;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    hide_paragraph_text: hideParagraph,
    background,
    text_color: textColor,
    global_icons: globalIcons,
    show_more: isShowMore,
  } = primary;

  const { prismicSettings } = useStaticQuery(featuresQuery);
  const { data: prismicData } = prismicSettings;

  const { features: settingsItems } = prismicData;

  const gridRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(9999);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (isShowMore) {
      const { scrollHeight } = gridRef.current;
      setMaxHeight(scrollHeight / 2);
    }
  }, [isShowMore]);

  // functions
  const handleShowMore = (event) => {
    event.preventDefault();
    const { scrollHeight } = gridRef.current;
    if (showMore) {
      setMaxHeight(scrollHeight / 2);
      setShowMore(false);
    } else {
      setMaxHeight(scrollHeight);
      setShowMore(true);
    }
  };

  // content checks
  const hasCta = ctaLink && ctaText?.text;

  const items = !globalIcons && dataItems.length > 0 ? dataItems : settingsItems;

  return (
    <Section
      className={`feature-grid ${hideParagraph ? 'no-paras' : ''} ${showMore || !isShowMore ? 'expand' : 'collapsed'} ${
        textColor === 'Dark Text' ? 'feature-grid-dark-text' : ''
      }`}
      style={background ? { backgroundColor: background } : {}}
    >
      {title?.html && <RichText className="feature-grid-main-title" html={title.html} h3 />}
      <div className="feature-grid-container collapsable-content" ref={gridRef} style={{ maxHeight: `${maxHeight}px` }}>
        {items.map((item) => {
          const { feature_icon: icon, feature_title: featureTitle, feature_content: content } = item;
          const featureTitleHtml = featureTitle?.html;
          const contentHtml = content?.html;
          return (
            <FadeIn className="feature-grid-item" key={featureTitle?.text}>
              {icon?.url && <Image className="feature-grid-image" image={icon} />}
              {featureTitleHtml && <RichText className="feature-grid-title" html={featureTitleHtml} h5 />}
              {contentHtml && !hideParagraph && <RichText className="feature-grid-content" html={contentHtml} />}
            </FadeIn>
          );
        })}
      </div>
      <FadeIn className="feature-grid-actions">
        {isShowMore && (
          <a href="#view-more" className="feature-grid-toggle" onClick={handleShowMore}>
            View {showMore ? 'less' : 'more'}
          </a>
        )}
        <div className="feature-grid-actions-right">
          {hasCta && (
            <Link to={ctaLink.url} className="button">
              {ctaText.text}
            </Link>
          )}
        </div>
      </FadeIn>
    </Section>
  );
};

export default FeatureGrid;
