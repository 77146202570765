import React, { useState, useEffect } from 'react';
import { Logo, Hamburger, Navigation } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import './styles.scss';

const topNavQuery = graphql`
  query {
    ...TopNavQuery
  }
`;

const Header = () => {
  const { prismicSettings } = useStaticQuery(topNavQuery);
  const { data: prismicData } = prismicSettings;
  const { main_navigation: mainNav, sublinks: mainSubLinks } = prismicData;

  const [showNav, setShowNav] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);

  const toggleNav = (event) => {
    event.preventDefault();
    setShowNav(!showNav);
  };

  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      document.body.classList.remove('hamburger-nav-open');
      setShowNav(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('hamburger-nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove('hamburger-nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    console.log('SCROLLTOP', scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
      unlockBody();
    };
  }, []);

  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
    return () => {
      unlockBody();
    };
  }, [showNav]);

  const navigationLinks = mainNav.map((link) => {
    const sublinks = mainSubLinks.filter((sublink) => sublink?.parent_link_title?.text === link?.link_text?.text);
    return {
      to: link.link?.url,
      text: link?.link_text?.text,
      sublinks: sublinks.map((sublink) => ({
        to: sublink.link?.url,
        text: sublink?.link_title?.text,
        subtext: sublink?.link_description?.html,
      })),
    };
  });

  return (
    <header className="header">
      <div className="wrapper">
        <Logo />
        <Hamburger onClick={toggleNav} active={showNav} theme="white" />
        <Navigation classNamePrefix="header" links={navigationLinks} useCta />
      </div>
    </header>
  );
};

export default Header;
