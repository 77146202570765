import React, { useEffect } from 'react';
import { Section } from 'components';
import './styles.scss';

const HubspotEmbed = (props) => {
  const { data, id } = props;
  const { primary } = data;
  const { embed_code: embedObj } = primary;

  const embedCode = embedObj?.text;

  const isMeeting = embedCode.indexOf('https://meetings.hubspot.com/') !== -1;

  useEffect(() => {
    if (!isMeeting) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: '5874976',
            formId: embedCode,
            target: `#form-${id}`,
          });
        }
      });
    } else {
      const script = document.createElement('script');
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Section className="hubspot-embed">
      {!isMeeting ? (
        <div className="hubspot-form-container">
          <span className="hubspot-form-title">Contact Us</span>
          <div id={`form-${id}`} className="hubspot-form" />
        </div>
      ) : (
        <div className="meetings-iframe-container" data-src={embedCode} />
      )}
    </Section>
  );
};

export default HubspotEmbed;
